html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  border-radius: 8px;
}

#root {
  min-height: 100%;
  background: linear-gradient(#fdd, #eef);

  canvas {
    display: block;
    outline: 0;
  }
}

.play-button {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &__inner {
    border: 3px solid #333;
    color: #222;
    font-size: 32px;
    padding: 15px 40px;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    i {
      margin-left: 0.5em;
    }
  }

  &:hover {
    .play-button__inner {
      background: #333;
      color: #fff;
    }
  }
}

.menu {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    line-height: 1;
    background: rgba(0, 0, 0, 0.5);
    width: 48px;
    height: 48px;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
}

.loading {
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 180px;
    height: 180px;
    border: 10px solid #f33;
    border-radius: 50%;
    box-sizing: border-box;
    animation: animation-loading 0.5s ease-in-out 0s infinite alternate;
  }
}

.error {
  position: fixed;
  z-index: 4000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(64, 0, 0, 0.85);
  padding: 20px;
  white-space: pre-wrap;
  text-align: center;
  color: #ff9;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.7;
}

.is-none {
  display: none !important;
}

@keyframes animation-loading {
  from {
    border-width: 20px;
    transform: scale(0);
  }
  to {
    border-width: 0;
    transform: scale(1);
  }
}
